<template>
  <v-card>
    <v-dialog v-model="isSubmitting" width="unset" persistent>
      <v-card>
        <v-card-title>Submitting your files...</v-card-title>
        <v-progress-linear indeterminate :color="FileStatus.color[FileStatus.VALID]"></v-progress-linear>
      </v-card>
    </v-dialog>

    <v-dialog v-model="submitCompleteMsg" width="unset">
      <v-card>
        <v-alert type="success" tile> Submission Complete </v-alert>
        <v-card-title>Your submission was successful</v-card-title>
        <v-card-text>
          All metadata has been transferred, the audio and artwork files are currently being transferred and processed.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="success" @click="submitCompleteMsg = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="submitErrorMsg" width="unset">
      <v-card>
        <v-alert type="error" tile> Submission Failed </v-alert>
        <v-card-title>Your submission failed</v-card-title>
        <v-card-text>{{ submitErrorMsgText }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" @click="submitErrorMsg = false">CLOSE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="rejectedFilesBar" centered app timeout="-1" vertical>
      <div class="mb-2">
        <v-icon :size="26" color="error">{{ icons.mdiUploadOff }}</v-icon>
        &nbsp;<strong>{{ rejectedFileList.length }}</strong> unsupported
        {{ rejectedFileList.length === 1 ? 'file has' : 'files have' }}
        been rejected for upload:
      </div>
      <ul>
        <li v-for="theFile in rejectedFileList.slice(-5).reverse()" :key="theFile.getId()">
          {{ theFile.filename }}
        </li>
        <li v-if="rejectedFileList.length > 5">...</li>
      </ul>
      <template v-slot:action>
        <v-btn color="error" @click="rejectedFilesBar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <FileErrorsDialog v-model="errorDialog" :file="errorDialogFile" @close="closeErrorDialog"></FileErrorsDialog>

    <v-sheet class="d-flex mb-0">
      <v-sheet class="mr-auto">
        <v-card-title> {{ organisationName }}'s Workspace </v-card-title>
      </v-sheet>

      <v-sheet v-if="enableUpdateMode" class="pa-4">
        <v-btn-toggle v-model="strategy" color="primary" mandatory @change="onStrategyChange">
          <v-btn value="insert" class="px-3 py-1" :disabled="isSubmitting"> Insert </v-btn>
          <v-btn value="update" class="px-3 py-1" :disabled="isSubmitting"> Update </v-btn>
        </v-btn-toggle>
      </v-sheet>
    </v-sheet>

    <v-card-text>
      Drag and drop your files anywhere in this window to upload. Supported file types are:
      {{ FileTypes.supportedExtensions.join(', ') }}
    </v-card-text>

    <v-card-text>
      <input
        ref="dummyFileInput"
        type="file"
        multiple
        style="display: none"
        :accept="FileTypes.supportedExtensions.join(',')"
        @change="onAddFileInputChange"
      />
      <v-btn
        :color="FileStatus.color[FileStatus.UPLOADING]"
        class="mr-2 mt-2"
        @click="
          $refs.dummyFileInput.click()
          SessionRefresh.keepAlive()
        "
      >
        <v-icon>{{ icons.mdiUpload }}</v-icon>
        &nbsp; ADD FILES
      </v-btn>
      <v-btn :color="FileStatus.color[FileStatus.UPLOADING]" class="mr-2 mt-2" @click="downloadErrors">
        <v-icon>{{ icons.mdiDownload }}</v-icon>
        &nbsp; DOWNLOAD ERRORS
      </v-btn>
      <v-btn
        v-if="enableMetaTemplateDownload"
        :color="FileStatus.color[FileStatus.UPLOADING]"
        :class="['mr-2 mt-2', { 'disable-events': metaDownloading }]"
        :loading="metaDownloading"
        @click="downloadMetaTemplate"
      >
        <v-icon>{{ icons.mdiDownload }}</v-icon>
        &nbsp; DOWNLOAD META
      </v-btn>
      <v-btn :color="FileStatus.color[FileStatus.INVALID]" class="mr-2 mt-2" @click="deleteSelected">
        <v-icon>{{ icons.mdiDelete }}</v-icon>
        &nbsp; DELETE SELECTED
      </v-btn>
      <v-btn
        :color="FileStatus.color[FileStatus.INVALID]"
        :class="['mr-2 mt-2', { 'disable-events': isSubmitting || isClearingWorkspace }]"
        :disabled="isSubmitting"
        :loading="isClearingWorkspace"
        @click="deleteWorkspace"
      >
        <v-icon>{{ icons.mdiRestart }}</v-icon>
        &nbsp; DELETE SUBMISSION
      </v-btn>
      <v-btn
        :color="FileStatus.color[FileStatus.VALID]"
        :class="['mr-2 mt-2', { 'disable-events': isSubmitting || isClearingWorkspace }]"
        :disabled="isClearingWorkspace"
        :loading="isSubmitting"
        @click="submitWorkspace"
      >
        <v-icon>{{ icons.mdiCheckCircle }}</v-icon>
        &nbsp; SUBMIT
      </v-btn>
    </v-card-text>

    <LastSubmitSummary ref="lastSubmitSummary" />

    <v-alert :color="workspaceStatusColor()" :icon="icons.mdiUpload" class="mx-5" type="success" text border="left">
      <v-row>
        <v-col> Status: {{ workspaceStatusLabel() }} </v-col>
        <v-col> Files: {{ DB.totalCount() }} </v-col>
        <v-col>
          Uploading:
          {{ uploadingFileList.length + DropHandler.processingCount() }}
          <span v-if="DropHandler.isProcessing()">(processing)</span>
        </v-col>
        <v-col> Errors: {{ CrossValidateErrors.totalErrorCount() + DB.simpleErrorsCount() }} </v-col>
      </v-row>

      <v-progress-linear
        :indeterminate="uploadingFileList.length > 0"
        :color="workspaceStatusColor()"
      ></v-progress-linear>
    </v-alert>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="fileList"
      item-key="uploadUuid"
      class="table-rounded"
      hide-default-footer
      :items-per-page="-1"
      show-select
      :loading="loading || DropHandler.isProcessing()"
      loading-text="Loading... Please wait"
      :options.sync="options"
      @update:sort-by="onSortChange"
      @update:sort-desc="onSortChange"
    >
      <template #[`item.typeGroup`]="{ item }">
        <div class="text-no-wrap">
          <v-icon :size="20">{{ FileTypes.icons[item.typeGroup] }}</v-icon>
          &nbsp;{{ item.typeGroup | ucfirst }}
        </div>
      </template>

      <!-- name -->
      <template #[`item.filename`]="{ item }">
        <div class="d-flex flex-column">
          <span
            class="d-block font-weight-semibold text--primary text-truncate"
            :title="item.filename"
            style="width: 300px"
          >
            {{ item.filename }}
          </span>
          <small>{{ item.getStatusText() }}</small>
          <v-progress-linear
            :value="item.progress"
            :color="FileStatus.color[item.getStatus()]"
            :indeterminate="item.getStatus() === FileStatus.PENDING"
          ></v-progress-linear>
        </div>
      </template>

      <!-- status -->
      <template #[`item.status`]="{ item }">
        <v-chip small :color="FileStatus.color[item.getStatus()]" class="font-weight-medium" outlined>
          <v-icon v-if="[FileStatus.VALID, FileStatus.INVALID].includes(item.getStatus())" :size="14">
            {{ item.getStatus() == FileStatus.VALID ? icons.mdiCheckCircle : icons.mdiAlertCircle }}
          </v-icon>
          <v-progress-circular
            v-if="[FileStatus.UPLOADING, FileStatus.PENDING].includes(item.getStatus())"
            :size="12"
            indeterminate
          ></v-progress-circular>
          &nbsp;
          {{ FileStatus.label[item.getStatus()] }}
        </v-chip>
      </template>

      <template #[`item.error_count`]="{ item }">
        <div
          :class="item.getErrorCount() > 0 ? 'error--text font-weight-bold' : ''"
          style="cursor: pointer"
          @click="openErrorDialog(item)"
        >
          {{ item.getErrorCount() }}
        </div>
      </template>

      <template #[`item.error_text`]="{ item }">
        <div class="error--text" style="cursor: pointer" @click="openErrorDialog(item)">
          <div v-for="errMsg in item.getErrorSummaryTextArray()" :key="errMsg">{{ errMsg }}</div>
        </div>
      </template>

      <template #[`item.actions`]="{ item }">
        <div class="mt-1">
          <v-btn
            v-if="item.hasErrors()"
            :color="FileStatus.color[FileStatus.UPLOADING]"
            small
            class="mr-1 mb-1"
            @click="openErrorDialog(item)"
          >
            <v-icon :size="16">{{ icons.mdiMagnify }}</v-icon>
            &nbsp; VIEW ERRORS
          </v-btn>
          <v-btn
            :color="FileStatus.color[FileStatus.UPLOADING]"
            small
            class="mr-1 mb-1"
            :loading="item.isDownloading"
            :disabled="!item.isDownloadable()"
            @click="downloadFile(item)"
          >
            <v-icon :size="16">{{ icons.mdiDownload }}</v-icon>
            &nbsp; DOWNLOAD
          </v-btn>
          <v-btn
            v-if="item.status !== FileStatus.UPLOADING"
            :color="FileStatus.color[FileStatus.INVALID]"
            small
            class="mr-1 mb-1"
            @click="removeSingleFile(item)"
          >
            <v-icon :size="16">{{ icons.mdiDelete }}</v-icon>
            &nbsp; DELETE
          </v-btn>
        </div>
      </template>

      <template v-slot:footer>
        <center style="border-top: 1px solid #e8e8e8">
          <v-btn
            :color="FileStatus.color[FileStatus.UPLOADING]"
            class="ma-2"
            :disabled="DB.isNextDisabled()"
            @click="loadNextPage"
          >
            Load more
          </v-btn>
        </center>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import icons from '@/utils/icons'
import onUserLoaded from '@/utils/onUserLoaded'
import downloadBlob from '@/utils/downloadBlob'
import downloadUrl from '@/utils/downloadUrl'
import submitErrorMsg from '@/utils/submitErrorMsg'
import FileErrorsDialog from '@/views/dashboard/FileErrorsDialog.vue'
import LastSubmitSummary from '@/views/dashboard/LastSubmitSummary.vue'
import UploadFile from '@/classes/UploadFile'
import FileTypes from '@/classes/FileTypes'
import FileStatus from '@/classes/FileStatus'
import FileStorage from '@/classes/FileStorage'
import UploadsApi from '@/classes/UploadsApi'
import UploadsPaginator from '@/classes/UploadsPaginator'
import CrossValidateErrors from '@/classes/CrossValidateErrors'
import DB from '@/classes/DB'
import EventBus from '@/classes/EventBus'
import ErrorCsvGenerator from '@/classes/ErrorCsvGenerator'
import SessionRefresh from '@/classes/SessionRefresh'
import User from '@/classes/User'
import DropHandler from '@/classes/DropHandler'

export default {
  components: {
    FileErrorsDialog,
    LastSubmitSummary,
  },
  data() {
    return {
      isInitialised: false,
      organisationName: 'My Organisation',
      organisationUuid: '???',
      strategy: null,
      fileList: [],
      selected: [],
      options: {},
      loading: false,
      errorDialog: false,
      errorDialogFile: new UploadFile(),
      filterFilename: '',
      headers: [
        { text: 'FILENAME', value: 'filename' },
        { text: 'TYPE', value: 'typeGroup' },
        { text: 'STATUS', value: 'status' },
        { text: 'ERRORS', value: 'error_count', sortable: false },
        { text: 'ERROR MESSAGES', value: 'error_text', sortable: false },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ],
      FileTypes,
      FileStatus,
      icons,
      filterFileType: null,
      filterFileStatus: null,
      SessionRefresh,
      UploadsPaginator,
      CrossValidateErrors,
      DB,
      DropHandler,
      lastNotificationTimestamp: 0,
      rejectedFilesBar: false,
      submitCompleteMsg: false,
      submitErrorMsg: false,
      submitErrorMsgText: '',
      isSubmitting: false,
      isClearingWorkspace: false,
      enableUpdateMode: process.env.VUE_APP_ENABLE_UPDATE_MODE === 'true',
      enableMetaTemplateDownload: process.env.VUE_APP_ENABLE_META_TEMPLATE_DOWNLOAD === 'true',
      metaUpdateTemplateFile: process.env.VUE_APP_META_UPDATE_TEMPLATE_FILE,
      metaDownloading: false,
    }
  },
  computed: {
    preUploadQueueFileList: function preUploadQueueFileList() {
      return this.$store.state.preUploadQueueFileList
    },
    uploadingFileList: function uploadingFileList() {
      return this.$store.state.uploadingFileList
    },
    activeUploadFileList: function activeUploadFileList() {
      return this.$store.state.activeUploadFileList
    },
    rejectedFileList: function rejectedFileList() {
      return this.$store.state.rejectedFileList
    },
  },
  watch: {
    rejectedFileList: function rejectedFileList() {
      this.rejectedFilesBar = true
      if (this.rejectedFilesBarTimeout) {
        clearTimeout(this.rejectedFilesBarTimeout)
      }
      this.rejectedFilesBarTimeout = setTimeout(() => {
        this.rejectedFilesBar = false
      }, 7000)
    },
    isSubmitting: function isSubmitting() {
      DropHandler.disabled = this.isSubmitting || this.isClearingWorkspace
    },
    isClearingWorkspace: function isClearingWorkspace() {
      DropHandler.disabled = this.isSubmitting || this.isClearingWorkspace
    },
  },
  mounted() {
    onUserLoaded(this.initialise)
    EventBus.$on('newFileAdded', () => {
      this.refreshPage()
    })
    EventBus.$on('newFileUploaded', thisFile => {
      CrossValidateErrors.startCrossValidating()
      if (thisFile.isMetadata() || this.strategy === 'update') {
        CrossValidateErrors.startTargetValidating()
      }
      this.refreshPage()
    })
    EventBus.$on('uploadFileListFilter', filterEvent => {
      this.filterFilename = filterEvent.filterFilename
      this.filterFileStatus = filterEvent.filterFileStatus
      this.filterFileType = filterEvent.filterFileType
      this.onFilterChange()
    })
    EventBus.$on('logout', () => {
      this.cancelAllUploads()
    })
  },
  methods: {
    async initialise() {
      if (!User.isLoggedIn) {
        return
      }

      this.isInitialised = false
      this.loading = true

      // Fetch organisation record
      UploadsApi.getOrganisation().then(organisation => {
        const wasInitialised = this.isInitialised
        if (wasInitialised) {
          this.isInitialised = false
        }
        this.organisationName = organisation.organisationName
        this.organisationUuid = organisation.organisationUuid
        this.strategy = organisation?.strategy || 'insert'
        this.isSubmitting = organisation?.isSubmitting || false
        if (organisation?.isValidating) {
          CrossValidateErrors.startTargetValidating(organisation?.targetValidationTimestamp)
        }
        this.$refs.lastSubmitSummary.init(organisation)
        EventBus.$emit('organisationLoaded', organisation)
        if (wasInitialised) {
          this.isInitialised = true
        }
      })

      // Fetch and wait for upload and error data
      const [uploadsData, crossValErrors, appErrors] = await Promise.all([
        UploadsApi.listUploads({ itemsPerPage: -1 }),
        FileStorage.getCrossValErrorsCache(),
        FileStorage.getAppErrorsCache(),
      ])

      // Build local upload/error state and display first page
      CrossValidateErrors.setCrossValErrors(crossValErrors)
      CrossValidateErrors.setTargetAppErrors(appErrors)
      DB.init(uploadsData.Items.map(UploadFile.createFromApiFileCompressed))
      this.loading = false
      this.getDataFromApi({ first: true })
      this.isInitialised = true

      // Start notifications polling
      this.lastNotificationTimestamp = uploadsData.fetchedTimestamp
      this.checkForApiChanges()

      // Resume any in progress uploads
      this.resumeUploads()
    },
    checkForApiChanges() {
      if (!User.isLoggedIn) {
        return
      }
      const params = {
        lastTimestamp: this.lastNotificationTimestamp,
      }
      UploadsApi.getNewNotifications(params)
        .then(response => {
          if (response.Count > 0) {
            let isPendingCrossVal = false
            let flagFetchCrossValErrors = false
            let flagFetchTargetAppErrors = false
            let targetValidationTimestamp = null
            let flagRefreshPage = false

            response.Items.forEach(notification => {
              const { notificationType, additionalData, createdTimestamp } = notification
              switch (notificationType) {
                case 'submitStarted':
                  this.isSubmitting = true
                  this.$refs.lastSubmitSummary.submitStarted()
                  break
                case 'dupeSubmit':
                  // ignore?
                  break
                case 'submitSuccess':
                  this.isSubmitting = false
                  this.submitCompleteMsg = true
                  this.$refs.lastSubmitSummary.submitSuccess()
                  break
                case 'submitFailed':
                  this.showSubmitError(submitErrorMsg(additionalData, this.organisationUuid))
                  this.$refs.lastSubmitSummary.submitFailed(this.submitErrorMsgText)
                  break
                case 'crossValidationComplete':
                  flagFetchCrossValErrors = true
                  isPendingCrossVal = additionalData?.isPending
                  break
                case 'targetValidationStarted':
                  CrossValidateErrors.startTargetValidating(createdTimestamp)
                  break
                case 'targetValidationComplete':
                  flagFetchTargetAppErrors = true
                  targetValidationTimestamp = additionalData?.requestTimestamp
                  break
                case 'addUpload':
                  DB.addUpload(UploadFile.createFromApiFile(additionalData))
                  flagRefreshPage = true
                  CrossValidateErrors.startCrossValidating()
                  break
                case 'updateUpload':
                  DB.updateUpload(UploadFile.createFromApiFile(additionalData))
                  flagRefreshPage = true
                  CrossValidateErrors.startCrossValidating()
                  break
                case 'deleteUpload':
                  DB.deleteUpload(additionalData)
                  flagRefreshPage = true
                  CrossValidateErrors.startCrossValidating()
                  break
                case 'workspaceCleared':
                  CrossValidateErrors.setTargetAppErrors({})
                  CrossValidateErrors.stopTargetValidating(createdTimestamp)
                  DB.clearWorkspace()
                  this.isClearingWorkspace = false
                  flagRefreshPage = true
                  break
                case 'settingsChanged':
                  if (additionalData?.strategy && additionalData.strategy !== this.strategy) {
                    this.strategy = additionalData.strategy
                    CrossValidateErrors.startCrossValidating()
                  }
                  break
                case 'metaDownloadReady':
                  if (this.metaDownloading) {
                    this.downloadMetaTemplateActual()
                  }
                  break
                default:
                  console.error(`Unexpected notificationType: ${notificationType}`)
              }

              this.lastNotificationTimestamp = createdTimestamp
            })

            if (flagFetchCrossValErrors) {
              FileStorage.getCrossValErrorsCache().then(errors => {
                CrossValidateErrors.setCrossValErrors(errors)
                if (!isPendingCrossVal) {
                  CrossValidateErrors.stopCrossValidating()
                }
                DB.refreshAllErrors()
                this.refreshPage()
              })
            }
            if (flagFetchTargetAppErrors) {
              FileStorage.getAppErrorsCache().then(errors => {
                CrossValidateErrors.setTargetAppErrors(errors)
                CrossValidateErrors.stopTargetValidating(targetValidationTimestamp)
                DB.refreshAllErrors()
                this.refreshPage()
              })
            }
            if (flagRefreshPage) {
              this.refreshPage()
            }
          }
        })
        .finally(() => {
          this.pollTimeout = setTimeout(this.checkForApiChanges, process.env.VUE_APP_POLL_INTERVAL)
        })
    },
    onRefreshBtn() {
      this.getDataFromApi({ first: true })
    },
    onFilterChange() {
      SessionRefresh.keepAlive()
      this.getDataFromApi({ first: true })
    },
    onSortChange() {
      this.onFilterChange()
    },
    loadPreviousPage() {
      this.getDataFromApi({ previous: true })
    },
    loadNextPage() {
      this.getDataFromApi({ next: true })
    },
    refreshPage() {
      this.getDataFromApi({ refresh: true })
      this.$forceUpdate()
    },
    getDataFromApi(extraParams = {}) {
      if (this.loading) {
        return
      }
      this.loading = true
      const params = {
        filename: this.filterFilename,
        status: this.filterFileStatus,
        type: this.filterFileType,
        ...this.options,
        ...extraParams,
      }
      const response = DB.getPage(params)
      this.fileList = response.Items
      this.loading = false
    },
    onAddFileInputChange(event) {
      SessionRefresh.keepAlive()
      const { files } = event.target
      for (let i = 0; i < files.length; i += 1) {
        this.addFile(UploadFile.createFromLocalFile(files[i]))
      }

      EventBus.$emit('newFileAdded')
    },
    async downloadFile(thisFileRaw) {
      const thisFile = thisFileRaw
      SessionRefresh.keepAlive()
      if (!thisFile.isDownloadable()) return

      thisFile.isDownloading = true
      this.$forceUpdate()
      await FileStorage.download(thisFile)
      thisFile.isDownloading = false
      this.$forceUpdate()
    },
    downloadErrors() {
      SessionRefresh.keepAlive()
      const blob = new Blob([ErrorCsvGenerator.generate()])
      downloadBlob(blob, `${this.organisationName} validation errors.csv`)
    },
    downloadMetaTemplate() {
      if (this.strategy === 'update') {
        downloadUrl(`/${this.metaUpdateTemplateFile}`, `${this.organisationName} Meta Template ${Date.now()}.xlsx`)

        return
      }
      this.metaDownloading = true
      UploadsApi.downloadMetaTemplate()
      SessionRefresh.keepAlive()
    },
    downloadMetaTemplateActual() {
      FileStorage.getMetaDownloadCache().then(content => {
        fetch(`data:application/octet-stream;base64,${content}`)
          .then(res => res.blob())
          .then(blob => {
            downloadBlob(blob, `${this.organisationName} Metadata ${Date.now()}.xlsx`)
            this.metaDownloading = false
          })
      })
    },
    onStrategyChange() {
      if (!this.isInitialised) {
        return
      }

      SessionRefresh.keepAlive()
      CrossValidateErrors.startCrossValidating()
      CrossValidateErrors.startTargetValidating()
      const params = {
        strategy: this.strategy,
      }
      UploadsApi.changeSettings(params)
    },
    submitWorkspace() {
      SessionRefresh.keepAlive()
      if (this.uploadingFileList.length || CrossValidateErrors.isValidating()) {
        this.showSubmitError('Please wait until all files have finished processing before trying again.')

        return
      }
      this.$refs.lastSubmitSummary.submitStarted()
      this.isSubmitting = true
      UploadsApi.submitWorkspace()
    },
    deleteWorkspace() {
      SessionRefresh.keepAlive()
      this.isClearingWorkspace = true
      if (DB.totalCount()) {
        CrossValidateErrors.startCrossValidating()
      }
      this.refreshPage()
      UploadsApi.clearWorkspace()
    },
    deleteSelected() {
      this.deleteMultipleFiles(this.selected)
      this.selected = []
    },
    removeSingleFile(thisFile) {
      this.deleteMultipleFiles([thisFile])
    },
    deleteMultipleFiles(theFiles) {
      SessionRefresh.keepAlive()
      if (!theFiles?.length) {
        return
      }

      // Remove from UI
      let isLocalOnly = true
      let isMetadataDeleted = this.strategy === 'update'
      theFiles.forEach(thisFile => {
        DB.deleteUpload(thisFile.getId())
        if (thisFile.isUploaded()) {
          isLocalOnly = false
        }
        if (thisFile.isMetadata()) {
          isMetadataDeleted = true
        }
      })

      if (isLocalOnly) {
        this.refreshPage()

        return
      }

      // Trigger validation flags
      CrossValidateErrors.startCrossValidating()
      if (isMetadataDeleted) {
        CrossValidateErrors.startTargetValidating()
      }
      this.refreshPage()

      // Remove from backend
      const uploadUuids = theFiles.map(thisFile => (thisFile.isUploaded() ? thisFile.getId() : false)).filter(Boolean)
      const params = {
        uploadUuids,
      }
      UploadsApi.deleteUploads(params)
    },
    openErrorDialog(thisFile) {
      SessionRefresh.keepAlive()
      this.errorDialogFile = thisFile
      this.errorDialog = true
    },
    closeErrorDialog() {
      SessionRefresh.keepAlive()
      this.errorDialog = false
    },
    showSubmitError(msg) {
      this.isSubmitting = false
      this.submitErrorMsgText = msg
      this.submitErrorMsg = true
    },
    workspaceStatus() {
      if (this.uploadingFileList.length) {
        return FileStatus.UPLOADING
      }

      if (CrossValidateErrors.isValidating()) {
        return FileStatus.PENDING
      }

      if (DB.totalCount() === 0) {
        return null
      }

      if (CrossValidateErrors.hasErrors() || DB.hasErrors()) {
        return FileStatus.INVALID
      }

      return FileStatus.VALID
    },
    workspaceStatusColor() {
      const status = this.workspaceStatus()
      if (!status) {
        return FileStatus.color[FileStatus.UPLOADING]
      }

      return FileStatus.color[this.workspaceStatus()]
    },
    workspaceStatusLabel() {
      const status = this.workspaceStatus()
      if (!status) {
        return 'READY'
      }

      return FileStatus.label[this.workspaceStatus()]
    },
    ...mapMutations({
      addFile: 'addFile',
      resumeUploads: 'resumeUploads',
      cancelAllUploads: 'cancelAllUploads',
    }),
  },
}
</script>
